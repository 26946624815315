import * as React from "react";
import { graphql } from "gatsby";

import CardBrowser from "../components/CardBrowser/CardBrowser";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { BasicLabel, DifficultyLabel } from "../components/Label/Label";
import { IconButton } from "../components/Button/Button";
import Icon from "../components/Icon/Icon";
import TechStackJSON from "../data/tech/techInfo.json";

import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import MdxImgFrame from "../components/MDX/MdxImgFrame/MdxImgFrame";
import { MdxFigmaEmbed } from "../components/MDX/MdxIframe/MdxIframe";
import MdxGrid from "../components/MDX/MdxGrid/MdxGrid";
import { CodeInline } from "../components/Code/Code";
import Divider from "../components/Divider/Divider";

import * as styles from "../styles/pages/templates/templateFrontendMentorChallenge.module.scss";
import * as mdxStyles from "../styles/pages/templates/MdxTemplate.module.scss";

const FEMChallengeTemplate = ({ data }) => {
  const frontmatter = getMdxFrontmatterData(data);
  const femChallenge = getFemChallengeInfo(data, frontmatter.challengeId);
  const challengeTrack = getChallengeTrackInfo(data, frontmatter.challengeTrack);

  const crumbs = {
    trail: [
      {
        name: "All Works",
        url: "/work",
      },
      {
        name: "Frontend Mentor Challenge",
        url: "/work/frontend-mentor-challenge",
      },
    ],
    current: {
      name: `${femChallenge.title} (${frontmatter.challengeTrack})`,
    },
  };

  return (
    <div className={`${styles.content_wrapper}`}>
      <Breadcrumb config={crumbs} />
      <div className={styles.page_title_wrapper}>
        <h1>{femChallenge.title}</h1>
        <p>{frontmatter.description}</p>
      </div>

      <div className={styles.featImage_container}>
        <a href={frontmatter.liveLink ? frontmatter.liveLink : ""}>
          <CardBrowser
            className={styles.featImage_hover}
            featuredImg={femChallenge.gatsbyImage}
            alt="Feature Image of Challenge UI"
            label={<BasicLabel>View Live Site</BasicLabel>}
          />
        </a>
      </div>

      <div className={`${styles.challengeInfo_container}`}>
        <section id="Track" className={styles.challengeInfo_track}>
          <div className={styles.challengeInfo_track_header}>
            <h4>Challenge Track:</h4>
            <Icon icon={challengeTrack.icon} />
            <p>{challengeTrack.title}</p>
          </div>
          <p>{challengeTrack.description}</p>
        </section>

        <section id="Difficulty">
          <h4>Difficulty:</h4>
          <DifficultyLabel difficulty={femChallenge.difficulty} />
        </section>

        <section id="Links" className={styles.challengeInfo_links_wrapper}>
          <h4>Links: </h4>
          <div className={styles.row}>
            <IconButton
              className={styles.challengeInfo_link}
              iconName="arrow"
              url={frontmatter.liveLink}
            />
            <IconButton
              className={styles.challengeInfo_link}
              iconName="github"
              url={frontmatter.sourceCode}
            />
            <IconButton
              className={styles.challengeInfo_link}
              iconName="frontendmentor"
              url={femChallenge.frontendmentorLink}
            />
          </div>
        </section>

        <section id="Stack" className={styles.challengeInfo_stack}>
          <h4>Tech Stack:</h4>
          <ul className={styles.row}>
            {frontmatter.stack.map((item) => (
              <TechItemList tech={item} key={item} />
            ))}
          </ul>
        </section>

        <section id="Approaches" className={styles.challengeInfo_approaches}>
          <h4>Approaches</h4>
          <ul>
            {frontmatter.approaches.map((approach) => (
              <li key={approach}>{approach}</li>
            ))}
          </ul>
        </section>
      </div>

      <article className={mdxStyles.mdx_content}>
        <MDXProvider components={{ Divider, MdxImgFrame, MdxGrid, MdxFigmaEmbed, CodeInline }}>
          <MDXRenderer>{frontmatter.body}</MDXRenderer>
        </MDXProvider>
      </article>
    </div>
  );
};

export default FEMChallengeTemplate;

const TechItemList = ({ tech }) => {
  const matchedTech = TechStackJSON.find((obj) => obj.name === tech.toLowerCase());
  const { displayTitle, color } = matchedTech;
  return <li style={{ color: color, fontWeight: "500" }}>{displayTitle}</li>;
};

const getMdxFrontmatterData = (data) => {
  const { mdx } = data;
  const { frontmatter, body } = mdx;
  const { stack, challengeTrack, challengeId, approaches, liveLink, sourceCode, description } =
    frontmatter;

  return {
    stack: stack,
    challengeTrack: challengeTrack,
    challengeId: challengeId,
    approaches: approaches,
    liveLink: liveLink,
    sourceCode: sourceCode,
    description: description,
    body: body,
  };
};

const getFemChallengeInfo = (data, challengeId) => {
  const listofFemChallenges = data.allFemChallengeInfoJson.edges;
  const matchedChallenge = listofFemChallenges.filter(
    (item) => item.node.jsonId === challengeId
  )[0];
  return {
    gatsbyImage: matchedChallenge.node.image.childImageSharp,
    title: matchedChallenge.node.title,
    frontendmentorLink: matchedChallenge.node.frontendmentorLink,
    difficulty: matchedChallenge.node.difficulty,
  };
};

const getChallengeTrackInfo = (data, challengeTrack) => {
  const listOfChallengeTrack = data.allFemChallengeTrackJson.edges;
  const matchedChallengeTrack = listOfChallengeTrack.filter(
    (item) => item.node.title === challengeTrack
  )[0];

  return {
    icon: matchedChallengeTrack.node.icon,
    title: matchedChallengeTrack.node.title,
    description: matchedChallengeTrack.node.description,
  };
};

export const pageQuery = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        slug
        title
        stack
        challengeTrack
        challengeId
        approaches
        liveLink
        sourceCode
        description
      }
    }
    allFemChallengeInfoJson {
      edges {
        node {
          jsonId
          title
          frontendmentorLink
          difficulty
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allFemChallengeTrackJson {
      edges {
        node {
          icon
          description
          title
        }
      }
    }
  }
`;
