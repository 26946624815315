import * as React from "react";
import * as styles from "./MdxIframe.module.scss";

// export const MdxIframe = ({ src, width, height, style, figmaUrl }) => {
//   return (
//     <div className={styles.mdx_iframe}>
//       <div className={styles.mdx_mobile}>
//         <a href={figmaUrl}></a>
//         <iframe style={style} width={width} height={height} src={src} allowFullScreen></iframe>
//       </div>
//       <div className={styles.mdx_desktop}>
//         <iframe style={style} width={width} height={height} src={src} allowFullScreen></iframe>
//       </div>
//     </div>
//   );
// };

export const MdxFigmaEmbed = ({ figmaUrl, src }) => {
  return (
    <div className={styles.figma_container}>
      <a href={figmaUrl}>For Mobile</a>
      <iframe
        style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
        title="Figma Embedded File"
        width="800"
        height="450"
        src={src}
        allowFullScreen
      ></iframe>
    </div>
  );
};
