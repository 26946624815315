import * as React from "react";
import * as styles from "./Label.module.scss";

import iconReact from "../../images/icons/iconReact.svg";
import iconJS from "../../images/icons/iconJavascript.svg";

const icons = {
  React: {
    image: iconReact,
  },
  Vanilla: {
    image: iconJS,
  },
};

export const BasicLabel = ({ children, icon }) => {
  const iconRender = <img src={icon} alt="some icon name" />;
  return (
    <div className={`${styles.primary_label}`}>
      {icon ? <span className={styles.icon_container}>{iconRender}</span> : null}
      {children}
    </div>
  );
};

export const TagLabel = ({ type }) => {
  return (
    <div className={styles.tag_label}>
      <span>{type}</span>
    </div>
  );
};

export const IconLabel = ({ label }) => {
  return (
    <div className={`${styles.primary_label}`}>
      <img className={styles.techIcon} src={icons[label].image} alt={label}></img>
      <span>{label}</span>
    </div>
  );
};

const listOfDifficulties = [
  {
    no: 1,
    title: "Newbie",
    color: "rgba(38, 139, 210, 0.8)",
    backgroundColor: "rgba(38, 139, 210, 0.3)",
  },
  {
    no: 2,
    title: "Junior",
    color: "#4c9832",
    backgroundColor: "white",
  },
  {
    no: 3,
    title: "Intermediate",
    color: "#b8a400",
    backgroundColor: "white",
  },
  {
    no: 4,
    title: "Advanced",
    color: "rgba(106, 101, 224, 0.8)",
    backgroundColor: "rgba(106, 101, 224, 0.3)",
  },
  {
    no: 5,
    title: "Guru",
    color: "#DA5549",
    backgroundColor: "white",
  },
];

export const DifficultyLabel = ({ difficulty }) => {
  const matchedDifficultly = listOfDifficulties.find((obj) => obj.title === difficulty);
  let { backgroundColor, color, title, no } = matchedDifficultly;

  return (
    <div className={styles.difficulty_label_container}>
      <div className={styles.difficulty_label_number} style={{ backgroundColor: color }}>
        <span>{no}</span>
      </div>
      <div className={styles.difficulty_label_title} style={{ backgroundColor: backgroundColor }}>
        <span>{title}</span>
      </div>
    </div>
  );
};
