import * as React from "react";
import * as styles from "./Divider.module.scss";

const Divider = ({ children }) => {
  return (
    <div className={styles.divider}>
      <h2>{children}</h2>
    </div>
  );
};

export default Divider;
