import * as React from "react";
import * as styles from "./CardBrowser.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CardBrowser = ({
  label,
  featuredImg,
  alt,
  showShadow,
  hasHoverInteraction,
  children,
  className,
}) => {
  const featImage = getImage(featuredImg);

  return (
    <div
      className={`
      ${styles.browser_container}
      ${showShadow ? styles.shadow : ""} 
      ${hasHoverInteraction ? styles.hover : ""} 
      ${className}`}
    >
      <div className={styles.browser_bar}></div>
      <div className={`${styles.img_container} ${styles.img_aspectRatio}`}>
        <GatsbyImage image={featImage} alt={alt}></GatsbyImage>
        {label ? <div className={`${styles.label_position}`}>{label}</div> : null}
        {children}
      </div>
    </div>
  );
};

export default CardBrowser;
