import * as React from "react";

import iconReact from "../../images/icons/lineIcon/iconReact.svg";
import iconJS from "../../images/icons/lineIcon/iconJavascript.svg";

const Icon = ({ icon }) => {
  let MatchedIcon;

  switch (icon) {
    case "React":
      MatchedIcon = iconReact;
      break;
    case "Javascript":
      MatchedIcon = iconJS;
      break;
    default:
      console.log(`Unmatched Icon`);
  }

  return <MatchedIcon />;
};

export default Icon;
