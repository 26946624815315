// extracted by mini-css-extract-plugin
export var challengeInfo_approaches = "templateFrontendMentorChallenge-module--challengeInfo_approaches--ZLOvN";
export var challengeInfo_container = "templateFrontendMentorChallenge-module--challengeInfo_container--OWRDO";
export var challengeInfo_link = "templateFrontendMentorChallenge-module--challengeInfo_link--LnxBY";
export var challengeInfo_links = "templateFrontendMentorChallenge-module--challengeInfo_links--wzrmm";
export var challengeInfo_links_wrapper = "templateFrontendMentorChallenge-module--challengeInfo_links_wrapper--6C8rR";
export var challengeInfo_stack = "templateFrontendMentorChallenge-module--challengeInfo_stack--cMFBb";
export var challengeInfo_track = "templateFrontendMentorChallenge-module--challengeInfo_track--vZd+C";
export var challengeInfo_track_header = "templateFrontendMentorChallenge-module--challengeInfo_track_header--FYYEy";
export var content = "templateFrontendMentorChallenge-module--content--NKgLn";
export var content_wrapper = "templateFrontendMentorChallenge-module--content_wrapper--LVc5W";
export var featImage_container = "templateFrontendMentorChallenge-module--featImage_container--0WRnu";
export var featImage_hover = "templateFrontendMentorChallenge-module--featImage_hover--nVHtZ";
export var page_title_wrapper = "templateFrontendMentorChallenge-module--page_title_wrapper--FZ8gy";
export var row = "templateFrontendMentorChallenge-module--row--Cjtvx";